import { rentCompanies } from '@/utils/filters';
import styles from './RentCompanies.module.scss';
import Image from 'next/image';

interface RentCompaniesProps {
  noBorder?: boolean;
  isSaturate?: boolean;
}

export default function RentCompanies({
  noBorder = false,
  isSaturate = false,
}: RentCompaniesProps) {
  return (
    <div
      className={`${styles.brands} ${
        noBorder ? styles['brands__noBorder'] : ''
      } ${isSaturate ? styles['brands__isSaturate'] : ''}`}
    >
      <div className="container">
        <ul>
          {rentCompanies.map((rc) => (
            <li key={`footer-rent-companies-${rc.iata.toLowerCase()}`}>
              <Image
                src={`/img/companies/${rc.iata}.png`}
                alt={`${rc.name} Logo`}
                width={96}
                height={72}
              />

              {/* <img
                src={`/img/companies/${rc.iata}.png`}
                alt={`${rc.name} Logo`}
              /> */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
