'use client';

import { useTranslations, useLocale } from 'next-intl';

import { Link } from '@/navigation';
import { isURL } from '@/utils/url';
import { getTitle } from '@/utils/menu';
import { localesTypes } from '@/config/locales';
import { CmsMenuProps } from '@/types';
import Image from 'next/image';

import MoonkeyLogo from '@/components/layout/MoonkeyLogo';

import styles from './Footer.module.scss';
import RentCompanies from '@/components/ui/RentCompanies';

const MenuCategoriesItens = ({
  data,
  locale,
}: {
  data: any;
  locale: localesTypes;
}) => (
  <>
    {data.map((subitem: any) => {
      const subitemTitle = getTitle(
        {
          title: subitem.attributes?.name,
          title_en: subitem.attributes?.localizations.data[0]?.attributes?.name,
        },
        locale,
      );

      const currHref = subitem.attributes?.url;
      const LinkComponent = currHref && isURL(currHref) ? 'a' : Link;

      return (
        <li key={`footer-menu-item-category-link-${subitem.id}`}>
          <LinkComponent
            href={currHref}
            title={subitemTitle}
            className={styles.textLink}
          >
            {subitemTitle}
          </LinkComponent>
        </li>
      );
    })}
  </>
);

const MenuItens = ({ data, locale }: { data: any; locale: localesTypes }) => (
  <>
    {data.map((subitem: any) => {
      const subitemTitle = getTitle(
        {
          title: subitem.attributes?.title,
          title_en: subitem.attributes?.title_en,
        },
        locale,
      );

      const currHref = subitem.attributes?.url;
      const LinkComponent = currHref && isURL(currHref) ? 'a' : Link;

      return (
        <li key={`footer-menu-item-link-${subitem.id}`}>
          <LinkComponent
            href={currHref}
            title={subitemTitle}
            className={styles.textLink}
          >
            {subitemTitle}
          </LinkComponent>
        </li>
      );
    })}
  </>
);

const Footer = ({ menuData }: { menuData: CmsMenuProps }) => {
  const t = useTranslations('common');
  const locale = useLocale() as localesTypes;
  const version = process.env.version;

  const menuItems = menuData.attributes.items.data
    .filter((item) => item.attributes.title !== 'Produtos')
    .map((item) => ({
      ...item,
      attributes: {
        ...item.attributes,
        children: {
          ...item.attributes.children,
          data: item.attributes.children.data.filter(
            (child) => child.attributes.title !== 'Experiências',
          ),
        },
      },
    }));
  // console.log(
  //   'Itens do menu antes do filtro:',
  //   JSON.stringify(menuItems, null, 2),
  // );
  const contactItem = menuItems.find((item) => item.attributes.is_contact_menu);
  return (
    <footer className={styles.footer}>
      <div className={`container ${styles['footer__container']}`}>
        <div className={styles['footer__col']}>
          <div className={styles['footer__col--wrapper']}>
            <Image fill src="/img/mobility-logo.svg" alt="Mobility Logo" />
          </div>

          {contactItem && (
            <div className={styles['footer__about']}>
              <div>
                <p>São Paulo: {contactItem.attributes.phone_sp}</p>
                <p>
                  {t('other_regions')}: {contactItem.attributes.phone_global}
                </p>
                <p>E-mail: {contactItem.attributes.email}</p>
              </div>
              <div>
                <p>
                  {contactItem.attributes.address}
                  <br />
                  {t('zipcode')} {contactItem.attributes.zipcode}
                  <br />
                  {contactItem.attributes.city_uf}
                </p>
              </div>
            </div>
          )}
        </div>

        {menuItems
          .filter(
            (item) =>
              !item.attributes.is_contact_menu &&
              !item.attributes.is_companies_logo_menu,
          )
          .map((item) => {
            const isCategoriesMenu = Boolean(
              item.attributes.is_vehicles_categories_menu,
            );
            const title = getTitle(
              {
                title: item.attributes?.title,
                title_en: item.attributes?.title_en,
              },
              locale,
            );

            return (
              <div
                className={styles['footer__col']}
                key={`footer-menu-item-wrapper-${item.id}`}
              >
                <ul className={styles['footer__list']}>
                  <li>
                    <h4>{title}</h4>
                  </li>
                  {isCategoriesMenu && item.attributes.vehicles_categories ? (
                    <MenuCategoriesItens
                      data={item.attributes.vehicles_categories.data}
                      locale={locale}
                    />
                  ) : (
                    <MenuItens
                      data={item.attributes.children.data}
                      locale={locale}
                    />
                  )}
                </ul>
              </div>
            );
          })}
      </div>

      <RentCompanies />
    </footer>
  );
};

export default Footer;
