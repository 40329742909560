export interface CarCategoryItem {
  id: number;
  'pt-br': string;
  en: string;
}
export const carsCategories: CarCategoryItem[] = [
  {
    id: 20,
    'pt-br': 'Econômico',
    en: 'Econômico',
  },
  {
    id: 21,
    'pt-br': 'Compacto',
    en: 'Compacto',
  },
  {
    id: 22,
    'pt-br': 'Intermediário',
    en: 'Intermediário',
  },
  {
    id: 23,
    'pt-br': 'Standard / Full Size',
    en: 'Standard / Full Size',
  },
  {
    id: 24,
    'pt-br': 'Station Wagon',
    en: 'Station Wagon',
  },
  {
    id: 25,
    'pt-br': 'Premium',
    en: 'Premium',
  },
  {
    id: 26,
    'pt-br': 'Esportivo',
    en: 'Esportivo',
  },
  {
    id: 27,
    'pt-br': 'Conversivel',
    en: 'Conversivel',
  },
  {
    id: 28,
    'pt-br': 'SUV',
    en: 'SUV',
  },
  {
    id: 29,
    'pt-br': 'Van',
    en: 'Van',
  },
];

export const paymentsMethod = ['Prepayment', 'LocalPayment'];
export const carsTransmissions = ['Automático', 'Manual'];
export const carsFranchise = ['free', 'limited'];

export const carsFuel = [
  {
    code: 'Multi-combustível',
    i18n_code: 'fuel_gasoline',
  },
  {
    code: 'Elétrico',
    i18n_code: 'fuel_eletric',
  },
  {
    code: 'Petrol',
    i18n_code: 'fuel_diesel',
  },
  {
    code: 'Multie-combustível',
    i18n_code: 'fuel_alcohol',
  },
];

export const rentCompanies = [
  {
    iata: 'ZI',
    name: 'Avis',
  },
  {
    iata: 'ZE',
    name: 'Hertz',
  },
  {
    iata: 'AL',
    name: 'Alamo',
  },
  {
    iata: 'SX',
    name: 'Sixt',
  },
  {
    iata: 'ZL',
    name: 'National',
  },
  {
    iata: 'ZD',
    name: 'Budget',
  },
  {
    iata: 'ZR',
    name: 'Dollar',
  },
  {
    iata: 'ZT',
    name: 'Thrifty',
  },
  {
    iata: 'ET',
    name: 'Enterprise',
  },
  {
    iata: 'EP',
    name: 'Europcar',
  },
  // {
  //   iata: 'XX',
  //   name: 'Keddy',
  // },
  {
    iata: 'UN',
    name: 'Unidas',
  },
  {
    iata: 'MO',
    name: 'Movida',
  },
  {
    iata: 'LL',
    name: 'Localiza',
  },
];
